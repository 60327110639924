import { ITypeformActions, SHIFT_FORM, DISPLAY_TYPEFORM } from './actions';
import { ITypeformData } from './interfaces';

interface ITypeformInitialState {
  forms: ITypeformData[];
}

export type ITypeformState = ITypeformInitialState;

const INITIAL_STATE: ITypeformInitialState = {
  forms: [],
};

export const getInitialState = (): ITypeformState => ({
  ...INITIAL_STATE,
});

export default function typeformReducer(
  state: ITypeformState = INITIAL_STATE,
  action: ITypeformActions,
): ITypeformState {
  switch (action.type) {
    case SHIFT_FORM: {
      return {
        ...state,
        forms: state.forms.slice(1),
      };
    }
    case DISPLAY_TYPEFORM: {
      return {
        ...state,
        forms: [...state.forms, { ...action.payload }],
      };
    }
    default:
      return state;
  }
}
