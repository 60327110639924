import { ITypeformData } from './interfaces';

export const DISPLAY_TYPEFORM = 'typeform/DISPLAY_TYPEFORM';
export const SHIFT_FORM = 'typeform/SHIFT_FORM';
export const ON_SUBMIT_FIRST_LOGIN_FORM = 'typeform/ON_SUBMIT_FIRST_LOGIN_FORM';

export type ITypeformActions =
  | IDisplayTypeformAction
  | IShiftFormAction
  | IOnSubmitFirstLoginFormAction;

export interface IDisplayTypeformAction {
  payload: ITypeformData;
  type: typeof DISPLAY_TYPEFORM;
}

export interface IOnSubmitFirstLoginFormAction {
  payload: IOnSubmitFirstLoginFormData;
  type: typeof ON_SUBMIT_FIRST_LOGIN_FORM;
}

export interface IShiftFormAction {
  type: typeof SHIFT_FORM;
}

export interface IOnSubmitFirstLoginFormData {
  responseId: string;
}

export function displayTypeform(
  payload: ITypeformData,
): IDisplayTypeformAction {
  return {
    payload,
    type: DISPLAY_TYPEFORM,
  };
}

export function shiftForm(): IShiftFormAction {
  return { type: SHIFT_FORM };
}

export function onSubmitFirstLoginForm(payload: IOnSubmitFirstLoginFormData): IOnSubmitFirstLoginFormAction {
  return {
    payload,
    type: ON_SUBMIT_FIRST_LOGIN_FORM,
  };
}
