/* eslint-disable import/no-named-default */
import { all, spawn } from 'redux-saga/effects';

import { saga as activeUser } from './active-user';
import { saga as admin } from './admin';
import { default as app } from './app/saga';
import { saga as auth } from './auth';
import { saga as authLegacy } from './auth-legacy';
import { saga as billingSaga } from './billing';
import { saga as communitySaga } from './community';
import { saga as userProfileSaga } from './user-profile';
import { saga as iframeSaga } from './iframe';
import { saga as chat } from './chat';
import { saga as device } from './device';
import { saga as error } from './error';
import { default as gate } from './gate/saga';
import { saga as insights } from './insights';
import { saga as overlays } from './overlays';
import { saga as quest } from './quest';
import { saga as realtime } from './realtime';
import { saga as video } from './video';
import { saga as socials } from './socials';
import { saga as customPanelsSaga } from './custom-panels';
import { saga as livestreamSaga } from './livestream';
import { saga as appRouter } from './app-router';
import { saga as rolesSaga } from './user-roles';
import { saga as modalSaga } from './modals';
import { saga as thirdPartyAnalyticsSaga } from './third-party-analytics';
import { saga as channelAdmin } from './page';
import { saga as shopifySaga } from './shopify';
import { saga as developerSaga } from './developer';
import { saga as customerProfileSaga } from './customer-profile';
import { saga as inviteSaga } from './invite';
import { saga as forgotPasswordSaga } from './forgot-password';
import { saga as segmentAnalyticsSaga } from './segment-analytics';
import { saga as themesSaga } from './themes';
import { saga as wizardSaga } from './wizard';
import { saga as tippingSaga } from './tipping';
import { saga as typeFormSaga } from './typeform';
import { saga as bundleSaga } from './bundle';
import { saga as shippingAddressSaga } from './shipping-address';
import { notficationSaga } from './notification/saga';
import { saga as pollsSaga } from './polls';
import { saga as navigationv2Saga } from './navigationv2';
import { saga as ecommerceSaga } from './ecommerce';
import { saga as accountAddressSaga } from './account-address';
import selfServiceSignupSaga from './self-service-signup/saga';

const sagas = [
  // always load App first then load any sagas that depend on other sagas
  // to dispatch events that will be dispatched on app load
  app,
  iframeSaga,
  insights,
  segmentAnalyticsSaga,
  userProfileSaga,
  thirdPartyAnalyticsSaga,
  // Then everything else
  activeUser,
  admin,
  channelAdmin,
  appRouter,
  auth,
  authLegacy,
  chat,
  device,
  error,
  gate,
  inviteSaga,
  overlays,
  quest,
  realtime,
  video,
  rolesSaga,
  modalSaga,
  socials,
  customPanelsSaga,
  livestreamSaga,
  billingSaga,
  communitySaga,
  shopifySaga,
  developerSaga,
  customerProfileSaga,
  forgotPasswordSaga,
  themesSaga,
  wizardSaga,
  tippingSaga,
  typeFormSaga,
  bundleSaga,
  shippingAddressSaga,
  notficationSaga,
  pollsSaga,
  navigationv2Saga,
  ecommerceSaga,
  accountAddressSaga,
  selfServiceSignupSaga,
];

const rootSaga = function* () {
  yield all(sagas.map(saga => spawn(saga)));
};

export default rootSaga;
