import { joinKeySegments } from 'firebase-utils';
import { cloneDeep } from 'lodash';
import { IObject, ITheme } from 'models';
import { THEME_TYPES } from 'models/ITheme';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { setPendingAdminChange, setPendingPageDoc, TargetTypes } from 'services/admin';
import { setPreviewActiveTheme, SET_PREVIEW_ACTIVE_THEME } from 'services/admin/actions';
import { getPendingPageDocs, getPreviewActiveTheme, getTheme, getEditingPageDoc, getSettingsDefaultThemeId } from 'services/admin/selectors';
import { setObject, SET_OBJECT, SET_SITE } from 'services/app/actions';
import { getObjectId, getSite, getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { subscribe, UPDATE_DOCUMENT } from 'services/realtime';
import { IUpdateDocumentAction } from 'services/realtime/actions';
import { isListening, getDocument } from 'services/realtime/selectors';
import IState from 'services/state';
import { MAESTRO_PURPLE } from 'style/constants';
import { ISetAccentColorAction, ISetDefaultSiteThemeAction, IDeleteThemeAction, setTheme, SET_ACCENT_COLOR, SET_DEFAULT_SITE_THEME, DELETE_THEME, APPLY_THEME, IApplyThemeAction } from './actions';
import { deleteTheme, getThemeById, IDeleteThemeResponse } from './api';
import { getSavedTheme, getThemeId } from './selectors';
import { createAccentColors } from './utils';

export function* handlePageThemeSaga({ payload }: ReturnType<typeof setObject>) {
  const { object, loaded } = payload;
  if (!object || !loaded) {
    return;
  }

  if (!object.data.theme) {
    return;
  }

  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  const currentTheme = getSavedTheme(state);

  const { id: themeId, type } = object.data.theme;
  const isClassic = type === THEME_TYPES.CLASSIC;
  const currentThemeId = currentTheme._id;
  if (!currentTheme || !themeId) {
    return;
  }

  if (themeId === currentThemeId && !isClassic) {
    return;
  }

  const theme: ITheme = yield call(getThemeById, {
    primaryToken: primaryToken!,
    siteId,
    themeId,
  });

  const themeWithClassicOptions: ITheme = yield call(getClassicThemeOptionsFromTheme, theme);
  if (JSON.stringify(themeWithClassicOptions) === JSON.stringify(currentTheme)) {
    return;
  }

  yield put(setTheme({ theme: themeWithClassicOptions }));
}

export function* setAccentColorSaga({ payload }: ISetAccentColorAction) {
  const state: IState = yield select();
  const theme = getTheme(state);
  const object: IObject = getEditingPageDoc(state);

  const { accentSecondary } = yield call(createAccentColors, payload.color);
  const newObject = cloneDeep(object);
  newObject.data.theme = {
    ...newObject.data.theme,
    classicThemeOptions: {
      ...newObject.data.theme?.classicThemeOptions,
      accentPrimary: payload.color, // using payload.color here instead of createAccentColors returns because it removes the alpha/opacity
      accentSecondary,
    },
    id: theme._id!,
    type: theme.type,
  };

  yield put(setPendingPageDoc(object._id, newObject));
}

export function* setDefaultSiteThemeSaga({ payload }: ISetDefaultSiteThemeAction) {
  yield put(setPendingAdminChange(TargetTypes.DEFAULT_THEME_ID, payload.themeId));
}

export function* deleteThemeSaga({ payload }: IDeleteThemeAction) {
  const { themeId } = payload;
  const state: IState = yield select();
  const site = getSite(state);
  const primaryToken = getPrimaryToken(state);
  const siteId = site._id;

  const response: IDeleteThemeResponse = yield call(deleteTheme, {
    primaryToken: primaryToken!,
    siteId,
    themeId,
  });

  if (!response.deleted && response.pages.length) {
    yield put(showModal({ data: { pages: response.pages, feature: 'theme' }, kind: ModalKinds.activeOnPageModal }));
  }
}

export function* setPageThemeDataSaga({ payload }: ReturnType<typeof setPreviewActiveTheme>) {
  const { theme } = payload;
  if (!theme) {
    return;
  }
  const state: IState = yield select();
  const object = getEditingPageDoc(state);

  const color = theme.type === THEME_TYPES.CLASSIC ? (object.data.theme?.classicThemeOptions?.accentPrimary || MAESTRO_PURPLE) : MAESTRO_PURPLE;
  const { accentPrimary, accentSecondary } = yield call(createAccentColors, color);

  const newObject = cloneDeep(object);
  newObject.data.theme = {
    ...newObject.data.theme,
    id: theme._id!,
    type: theme.type,
    classicThemeOptions: {
      ...newObject.data.theme?.classicThemeOptions,
      accentPrimary,
      accentSecondary,
    },
  };

  yield put(setPendingPageDoc(object._id, newObject));
}

export function* watchThemesSaga() {
  const state: IState = yield select();
  const theme = getSavedTheme(state);
  yield call(watchThemeSaga, theme);
}

export function* watchNewThemesSaga({ payload }: IApplyThemeAction) {
  yield call(watchThemeSaga, payload.theme);
}

export function* watchThemeSaga(theme: ITheme) {
  const state: IState = yield select();
  const themeId = theme._id!;

  if (theme.type !== THEME_TYPES.CUSTOM) {
    return;
  }

  if (!isListening(state, 'themes', themeId)) {
    const path = joinKeySegments(['themes', themeId]);
    yield put(subscribe(path));
  }
}

export function* themeRealtimeUpdateSaga({ payload }: IUpdateDocumentAction) {
  const isTheme = /^themes/.test(payload.path);
  if (!isTheme || !payload.value) {
    return;
  }
  const theme = payload.value as ITheme;

  const state: IState = yield select();
  const currentThemeId = getThemeId(state);
  const isCurrentTheme = currentThemeId === theme._id;

  if (!isCurrentTheme) {
    return;
  }

  const themeWithClassicOptions: ITheme = yield call(getClassicThemeOptionsFromTheme, theme);
  yield put(setTheme({ theme: themeWithClassicOptions }));
  yield call(watchThemeSaga, themeWithClassicOptions);
}

export function* notFoundPageThemeSaga() {
  const state: IState = yield select();
  const objectId = getObjectId(state);
  if (objectId) {
    return;
  }

  const defaultSiteThemeId = getSettingsDefaultThemeId(state);

  if (!defaultSiteThemeId) {
    return;
  }

  const primaryToken = getPrimaryToken(state)!;
  const siteId = getSiteId(state);
  const defaultSiteTheme: ITheme = yield call(getThemeById, {
    primaryToken,
    siteId,
    themeId: defaultSiteThemeId,
  });

  if (!defaultSiteTheme) {
    return;
  }

  const defaultSiteThemeWithClassicOptions: ITheme = yield call(getClassicThemeOptionsFromTheme, defaultSiteTheme);
  yield put(setTheme({ theme: defaultSiteThemeWithClassicOptions }));
  yield call(watchThemeSaga, defaultSiteThemeWithClassicOptions);
}

export function* pageThemeRealtimeUpdateSaga({ payload }: IUpdateDocumentAction) {
  const isObjectPath = /^objects/.test(payload.path);
  if (!isObjectPath || !payload.value) {
    return;
  }

  const object: IObject = payload.value;
  const isPage = object.collection === 'pages';
  if (!isPage) {
    return;
  }

  const state: IState = yield select();
  const currentObjectId = getObjectId(state);
  if (currentObjectId !== object._id) {
    return;
  }

  const themeId = object.data.theme?.id;
  const type = object.data.theme?.type;
  if (!themeId) {
    return;
  }

  const currentThemeId = getThemeId(state);
  if (currentThemeId === themeId && type !== THEME_TYPES.CLASSIC) {
    return;
  }

  const primaryToken = getPrimaryToken(state)!;
  const siteId = getSiteId(state);
  const theme: ITheme = yield call(getThemeById, {
    primaryToken,
    siteId,
    themeId,
  });

  if (!theme) {
    return;
  }

  const themeWithClassicOptions: ITheme = yield call(getClassicThemeOptionsFromTheme, theme);
  yield put(setTheme({ theme: themeWithClassicOptions }));
  yield call(watchThemeSaga, themeWithClassicOptions);
}

export function* applyThemeSaga({ payload }: IApplyThemeAction) {
  const theme: ITheme = yield call(getClassicThemeOptionsFromTheme, payload.theme);
  yield put(setTheme({ theme }));
}

export function* getClassicThemeOptionsFromTheme(payload: ITheme) {
  const isClassic = payload.type === THEME_TYPES.CLASSIC;
  if (!isClassic) {
    return payload;
  }

  const state: IState = yield select();
  const object = getEditingPageDoc(state);

  if (!object) {
    return payload;
  }

  const realtimeObject = getDocument(state, 'objects', object._id);

  const classicThemeWithOptions = {
    ...payload,
    colors: {
      ...payload.colors,
      ...object.data.theme?.classicThemeOptions,
      ...realtimeObject?.data?.theme?.classicThemeOptions,
    },
  };

  return classicThemeWithOptions;
}

export function* verifyPendingPreviewActiveTheme({ payload }: ReturnType<typeof setObject>) {
  if (!payload.loaded || !payload.object) {
    return;
  }

  const state: IState = yield select();
  const pendingPageDocs = getPendingPageDocs(state);
  const previewActiveTheme = getPreviewActiveTheme(state);
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);

  const pendingPageDoc = pendingPageDocs[payload.object._id];
  const themeId = pendingPageDoc?.data?.theme?.id;
  if ((!pendingPageDoc || !themeId) && !!previewActiveTheme) {
    yield put(setPreviewActiveTheme(null));
    return;
  }

  if (themeId && (themeId !== previewActiveTheme?._id)) {
    const theme: ITheme = yield call(getThemeById, {
      primaryToken: primaryToken!,
      siteId,
      themeId,
    });

    if (!theme) {
      return;
    }
    yield put(setPreviewActiveTheme(theme));
  }
}

export default function* Saga() {
  yield takeLatest(SET_OBJECT, verifyPendingPreviewActiveTheme);
  yield takeLatest(SET_OBJECT, handlePageThemeSaga);
  yield takeEvery(SET_ACCENT_COLOR, setAccentColorSaga);
  yield takeLatest(SET_DEFAULT_SITE_THEME, setDefaultSiteThemeSaga);
  yield takeEvery(DELETE_THEME, deleteThemeSaga);
  yield takeEvery(SET_PREVIEW_ACTIVE_THEME, setPageThemeDataSaga);
  yield takeEvery(APPLY_THEME, watchNewThemesSaga);
  yield takeEvery(APPLY_THEME, applyThemeSaga);
  yield call(watchThemesSaga);
  yield takeEvery(UPDATE_DOCUMENT, pageThemeRealtimeUpdateSaga);
  yield takeEvery(UPDATE_DOCUMENT, themeRealtimeUpdateSaga);
  yield takeEvery(SET_SITE, notFoundPageThemeSaga);
}
