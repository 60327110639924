import type { IAuthPostMessagePayload, IAccessTokenData } from 'services/auth/models';
import { SET_OBJECT } from 'services/app/actions';
import { IObject, IVideo, PageType } from 'models';
import { StorageType } from 'services/persistence/utils';

export enum MaestroIFrameEvents {
  CHANNEL_CHANGE = 'channel:change',
  IFRAME_REQUEST_LOGIN = 'request:login',
  SUBSCRIPTION_REQUIRED = 'subscription:required',
  LOGIN_REQUIRED = 'login:required',
  IFRAME_REQUEST_LOGOUT = 'request:logout',
  MAESTRO_READY = 'maestro:ready',
  IFRAME_LOCALE_CHANGE = 'locale:change',
  VIDEO_CONTENT_READY = 'video:content:ready',
  NAVIGATION_INITIADED = 'navigation:initiated',
  WATCH_IN_PROGRESS = 'video:watch:progress',
}

export enum MaestroIFrameMessages {
  RETURN_ACCOUNT = 'return:account',
  LOGIN_COMPLETE = 'login:complete',
  LOGOUT_COMPLETE = 'logout:complete',
  ACCOUNT_SET = 'account:set',
  READ_STORAGE = 'read:storage',
  WRITE_STORAGE = 'write:storage',
  DELETE_STORAGE = 'delete:storage',
}

export enum IFrameSDKActions {
  REQUEST_LOGIN = 'request:login',
  REQUEST_LOGOUT = 'request:logout',
  REQUEST_LAYOUT = 'request:layout',
  LOCALE_CHANGE = 'locale:change',
  INIT_LOCALE = 'init:locale',
  GET_MAESTRO_ACCOUNT = 'get:maestro:account',
  STORAGE_READ = 'storage:read',
  STORAGE_WROTE = 'storage:wrote',
  STORAGE_DELETED = 'storage:deleted',
  SSU_SIGNUP = 'ssu:signup',
}

export interface IIFrameSDKMessage {
  account?: {
    email?: string;
    jwt?: string;
    name?: string;
    service: string;
    thirdPartyUserId?: string;
  }
  action: IFrameSDKActions;
}

export enum IFrameMessageSource {
  IFRAME_SDK = 'maestro:iframe:sdk:v1',
  MAESTRO_STUDIO = 'maestro:studio:v1',
  WATCH_TOGETHER = 'maestro:watch:together:panel',
}

export enum EventSource {
  SUBSCRIPTION_GATE = 'subscription:gate',
  LOGIN_GATE = 'login:gate',
  LOGIN_MODAL = 'login:modal',
  LOCALE_CHANGE_SUCCESS_SAGA = 'locale:change:success:saga',
  ROUTER_SAGA = 'iframe:sdk:router:saga',
  MESSAGE_SAGA = 'iframe:sdk:message:saga',
  LOGIN_SUCCESS_SAGA = 'login:success:saga',
  LOGOUT_SAGA = 'logout:saga',
}

export type MaestroReadyBasePayload = {
  channelStatus: 'live' | 'offline';
  pageType: PageType;
  seo?: IObject['seo'];
  slug: string;
};

export type IFrameSDKPostMessagePayload <T> =
  | {
    action: MaestroIFrameEvents.IFRAME_REQUEST_LOGIN;
    eventSource?: EventSource.LOGIN_GATE | EventSource.SUBSCRIPTION_GATE | EventSource.LOGIN_MODAL;
  }
  | {
    action: MaestroIFrameEvents.SUBSCRIPTION_REQUIRED;
    eventSource?: EventSource.SUBSCRIPTION_GATE;
    subscriptionDetails: {
      accountId: string | null;
      channelSlug: string | undefined;
      subscriptions: string[];
    };
  }
  | {
    action: MaestroIFrameEvents.IFRAME_REQUEST_LOGOUT;
    eventSource: EventSource.LOGOUT_SAGA;
  }
  | {
    action: MaestroIFrameEvents.CHANNEL_CHANGE;
    channelSlug: string | undefined;
    eventSource: EventSource.ROUTER_SAGA;
  }
  | {
    action: MaestroIFrameEvents.IFRAME_LOCALE_CHANGE;
    eventSource?: EventSource.LOCALE_CHANGE_SUCCESS_SAGA;
    locale: string;
  }
  | (
    {
      action: MaestroIFrameEvents.MAESTRO_READY;
    } & MaestroReadyBasePayload
  )
  | {
    account: IAccessTokenData | null;
    action: MaestroIFrameMessages.RETURN_ACCOUNT;
    eventSource?: EventSource.MESSAGE_SAGA;
    loggedIn: boolean;
  }
  | {
    action: MaestroIFrameMessages.LOGIN_COMPLETE;
    eventSource?: EventSource.LOGIN_SUCCESS_SAGA;
  }
  | {
    account: IAccessTokenData | null;
    action: MaestroIFrameMessages.ACCOUNT_SET;
  }
  | {
    action: MaestroIFrameMessages.LOGOUT_COMPLETE;
    eventSource?: EventSource.LOGOUT_SAGA;
  }
  | {
    action: MaestroIFrameEvents.LOGIN_REQUIRED;
    eventSource?: EventSource.LOGIN_GATE | EventSource.SUBSCRIPTION_GATE | EventSource.LOGIN_MODAL;
  }
  | {
    action: MaestroIFrameEvents.VIDEO_CONTENT_READY;
    pageInfo: MaestroReadyBasePayload;
    vod: IVideo;
  }
  | {
    action: MaestroIFrameEvents.NAVIGATION_INITIADED;
    href?: string;
    target?: string;
  }
  | {
    action: MaestroIFrameEvents.WATCH_IN_PROGRESS;
    playTime: number;
    videoId: string;
  }
  | {
    action: MaestroIFrameMessages.READ_STORAGE;
    id: string;
    key: string;
    storageType: StorageType;
  }
  | {
    action: MaestroIFrameMessages.DELETE_STORAGE;
    id: string;
    key: string;
    storageType: StorageType;
  }
  | {
    action: MaestroIFrameMessages.WRITE_STORAGE;
    id: string;
    key: string;
    storageType: StorageType;
    value: T;
  }
  | IAuthPostMessagePayload;

export interface IRouterIFrameSaga {
  payload: {
    loaded: boolean;
    object: any;
  }
  type: typeof SET_OBJECT;
}

export enum WatchTogetherActions {
  RENDER_LOGIN_MODAL = 'render:login:modal',
}

export interface IRenderLoginModalMessage {
  action: WatchTogetherActions.RENDER_LOGIN_MODAL;
}

export type WatchTogetherMessage = IRenderLoginModalMessage;

export enum MaestroStudioActions {
  END_STUDIO_SESSION_ACTION = 'end:studio:session:action',
  STUDIO_SESSION_STALLED = 'stalled:studio:session',
  STUDIO_SESSION_READY_ACTION = 'ready:studio:session:action',
  STUDIO_INVITE_CODE_AVAILABLE = 'studio:invitecode:available',
  STUDIO_SESSION_NOT_READY_ACTION = 'notready:studio:session:action',
}

export interface IMaestroStudioMessage {
  action: MaestroStudioActions;
  sessionId: string;
}
