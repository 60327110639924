export enum ITypeFormTypes {
  POP_OVER = 'popOver',
  SIDE_TAB = 'sideTab',
  POP_UP_BUTTON = 'popUpButton',
  SLIDER_BUTTON = 'sliderButton',
  WIDGET = 'widget',
}

export enum TypeformPositions {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TypeformRedirectTargets {
  TOP = '_top',
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
}

export interface OnSubmitParams {
  responseId: string;
}

// Check docs for more info: https://developer.typeform.com/embed/react/ and https://developer.typeform.com/embed/configuration/
export interface ITypeformData {
  autoClose?: number | boolean;
  autoResize?: string | boolean;
  buttonColor?: string;
  buttonText?: string;
  chat?: boolean;
  container?: HTMLElement;
  customIcon?: string;
  disableAutoFocus?: boolean;
  enableSandbox?: boolean;
  formKey: string;
  formType: ITypeFormTypes;
  height?: number;
  hidden?: object;
  hideFooter?: boolean;
  hideHeaders?: boolean;
  iframeProps?: object;
  inlineOnMobile?: boolean;
  keepSession?: boolean;
  lazy?: boolean;
  medium?: string;
  mediumVersion?: string;
  notificationDays?: number;
  onClose?: () => void;
  onEndingButtonClicked?: () => void;
  onHeightChanged?: () => void;
  onQuestionChanged?: () => void;
  onReady?: () => void;
  onSubmit?: ({ responseId }: OnSubmitParams) => void;
  opacity?: number;
  open?: string;
  openValue?: number;
  position?: TypeformPositions;
  redirectTarget?: TypeformRedirectTargets;
  shareGaInstance?: string | boolean;
  size?: number;
  source?: string;
  style?: any;
  tooltip?: string;
  tracking?: object;
  transitiveSearchParams?: string[];
  width?: number;
}
