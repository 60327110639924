import invariant from 'invariant';
import EventBuilder from './EventBuilder';

/* eslint-disable class-methods-use-this */

class PanelEventBuilder extends EventBuilder {
  constructor() {
    super('panel');
  }

  buildOntology(action, doc, extra = {}) {
    const { class: classOverride } = extra.overrides || {};
    return {
      /* eslint-disable sort-keys */
      phylum: action, // TODO: Add validation?
      class: classOverride || doc?.renderer?.panelType || doc?.data?.kind,
      order: doc?.renderer?.id || doc?._id,
      family: doc?.renderer?.panelName || doc?.data?.title,
    };
  }

  buildMetadata(action, doc, extra = {}) {
    const { class: classOverride } = extra.overrides || {};
    const { data, renderer, doc: nestedDoc } = doc || {};
    const { data: nestedData } = nestedDoc || {};
    const kind = classOverride || renderer?.panelType || data?.kind || nestedData?.kind;
    if (!kind) { return null; }
    invariant(
      (data || renderer || nestedData) && kind,
      'Panel data or renderer missing in buildOntology',
    );
    const { overrides, ...extraNoOverrides } = extra || {};
    switch (kind) {
      case 'question': {
        return {
          collection: doc.collection ? 'objects' : 'panel',
          action: extra.action,
          question: extra.question ? extra.question : undefined,
          type: extra.type ? extra.type : undefined,
        };
      }
      case 'commerce':
      case 'video':
      case 'social':
      case 'multiple_choice':
      case 'channel_select':
      case 'group':
      case 'info_panel':
      case 'groups':
      case 'html':
      case 'image':
      case 'iframe':
      case 'newsletter':
      case 'now_playing':
      case 'playByPlay':
      case 'playerStats':
      case 'portal_menu':
      case 'people':
      case 'person':
      case 'playlist':
      case 'profile':
      case 'quests':
      case 'schedule':
      case 'chat':
      case 'portal_schedule':
      case 'twitch_chat':
      case 'quick_pick':
      case 'peopleGroups':
      case 'achievement_leaderboard':
      case 'fortniteDuosMatch':
      case 'fortniteSinglesMatch':
      case 'fortnitePlayByPlay':
      case 'pubgMatch':
      case 'shopify':
      case 'shopifyBlockPanel':
      case 'fortniteLeaderboardBlockPanel':
      case 'image_v2':
      case 'selfsignup_welcome':
      case 'tips':
        return extraNoOverrides || null;
      default:
        invariant(
          false,
          `PanelEventBuilder: Unknown panel kind ${JSON.stringify(kind)}`,
        );
        return null; // Appease linter; invariant throws
    }
  }
}
/* eslint-enable */

export default new PanelEventBuilder();
