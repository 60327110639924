import { put, takeEvery, select } from 'redux-saga/effects';
import { saveSiteSettings } from 'services/admin';
import { getSiteTags } from 'services/app/selectors';
import { ON_SUBMIT_FIRST_LOGIN_FORM, shiftForm } from './actions';
import { settingsTypes } from 'services/admin/constants';
import IState from 'services/state';
import { ISiteTags } from 'services/app/constants';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';

export function* onSubmitFirstLoginFormSaga() {
  const state: IState = yield select();
  const currentTags: string[] = getSiteTags(state);
  const tagToRemove = ISiteTags.FIRST_ADMIN_LOGIN;
  const newTags = currentTags.filter((tag: string) => tag !== tagToRemove);
  yield put(shiftForm());
  yield put(saveSiteSettings(settingsTypes.TAGS, newTags));
  yield put(showModal({ data: { locked: false }, kind: ModalKinds.wizardMain }));
}

export default function* typeFormSaga() {
  yield takeEvery(ON_SUBMIT_FIRST_LOGIN_FORM, onSubmitFirstLoginFormSaga);
  // TODO: Fetch typeform answers and track using segment
}
