import EventBuilder from './EventBuilder';

/* eslint-disable class-methods-use-this */
class QuestEventBuilder extends EventBuilder {
  constructor() {
    super('quest');
  }

  buildOntology(action, questData, extra) {
    if (action === 'view') {
      return {
        /* eslint-disable sort-keys */
        phylum: action,
        class: 'panel',
        order: extra.order,
        family: extra.family,
      };
    }

    if (action === 'claim') {
      const { type } = extra;
      return {
        /* eslint-disable sort-keys */
        phylum: 'claim',
        class: type,
        order: questData._id,
        family: questData.title,
      };
    }
    return null;
  }

  buildMetadata(action, questData, extra) {
    if (action === 'claim') {
      return {
        /* eslint-disable sort-keys */
        service: extra.service,
        code: extra.code,
        error: extra.error,
        prize_mode: extra.prize_mode,
        created: questData.created,
        tasks: questData.actions?.length || 0,
      };
    }

    return null;
  }
}
/* eslint-enable */

export default new QuestEventBuilder();
