// tslint:disable:no-console
import { call, put, select } from 'redux-saga/effects';
import { makeSaga } from 'redux-utils';
import { makeCached } from 'utils/cache';
import { flattenCalls } from 'utils';
import { updateDocument } from 'services/realtime/actions';
import { getNotification } from '../api';
import { getPrimaryToken } from 'services/auth';
import type IState from 'services/state';
import type { INotification, IPendingNotificationList } from 'models/INotification';
import { pendingNotificationsCollection } from './constants';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';

const getNotificationCached = makeCached(
  flattenCalls(
    getNotification,
  ),
);

const dedupList = new Set<string>();

export const notficationReceivedSaga = makeSaga(
  { updateDocument },
  function* ({ payload }) {
    const state: IState = yield select();
    const token = getPrimaryToken(state);

    if (!token || payload.path.indexOf(pendingNotificationsCollection + '/') !== 0)
      return;

    const { list } = payload.value as IPendingNotificationList;

    if (!list)
      return;

    for (const item of Object.values(list)) {
      if (!item || item.expires <= Date.now() || dedupList.has(item.notificationId))
        continue;

      dedupList.add(item.notificationId);

      try {
        const notification: INotification = yield call(
          getNotificationCached.cachedFn,
          token,
          item.notificationId,
        );

        switch (notification.payload.type) {
          case 'joinStudioStageInvite': {
            yield put(
              showModal({
                kind: ModalKinds.studioInviteReceived,
                data: {
                  notificationId: notification._id,
                  studioInviteCode: notification.payload.studioInviteCode,
                },
              }),
            );
            break;
          }
          default: {
            console.warn('Received unknown notification', notification);
            break;
          }
        }
      } catch (error) {
        console.error(`Error when handling notification`, item, error);
      }
    }
  },
);
