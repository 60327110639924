import axios from 'axios';
import qs from 'qs';

export const postAction = async event => (
  axios({
    data: qs.stringify(event),
    method: 'POST',
    url: 'https://insights.maestro.io/action',
  })
);

// for debugging add google analytics debugger and make sure no
// other iframes are sending out GA calls
export const loadGoogleAnalytics = (customGAId) => {
  // initializing ga so it runs on load
  window.ga = window.ga || ((...args) => {
    window.ga.q = window.ga.q || [];
    window.ga.q.push(args);
  });
  window.ga.l = +new Date();

  // assign maestro trackingId
  window.ga('create', {
    cookieDomain: 'auto',
    name: 'maestro',
    trackingId: 'UA-66886055-3',
  });

  // assign customGAid
  if (customGAId) {
    window.ga('create', {
      cookieDomain: 'auto',
      name: 'client',
      trackingId: customGAId,
    });
  }

  // load analytics
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    js.src = 'https://www.google-analytics.com/analytics.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'google_analytics_script_id'));
};
